import { AttributeInformation } from '@bbx/common/types/ad-detail/AdvertDetails'

export const getFirstAttributeLabel = (attributeInformations: AttributeInformation[] | null, code: string) => {
    return getFirstAttribute(attributeInformations, code)?.label
}

export const getFirstAttribute = (attributeInformations: AttributeInformation[] | null, code: string) => {
    const attribute = attributeInformations?.find((attributeInformation) => attributeInformation.treeAttributeElement.code === code)
    return attribute?.values[0]
}
