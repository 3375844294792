import React, { FunctionComponent } from 'react'
import { printDisplayBlockFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printDisplayBlockFirefoxWorkaroundCss'
import { CollapsibleDescription } from '../../../common/Description/Description'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { SpaceProps } from '@wh-components/system/space'
import { Box } from '@wh-components/core/Box/Box'

interface BapDescriptionBoxProps {
    advertDetails: AdvertDetails
}

export const BapDescriptionBox: FunctionComponent<BapDescriptionBoxProps & SpaceProps> = ({ advertDetails, ...props }) => {
    return (
        <Box display="flex" gap="m" flexDirection="column" css={printDisplayBlockFirefoxWorkaroundCss} {...props}>
            <CollapsibleDescription
                key={advertDetails.id}
                displayHeading={{ phone: 'none', tablet: 'block' }}
                heading="Beschreibung"
                description={getAttributeValue(advertDetails.attributes.attribute, 'DESCRIPTION')}
                taggingData={advertDetails.taggingData}
                taggingEvent="addetail_expand_description_click"
                rows={22}
            />
        </Box>
    )
}
