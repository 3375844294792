import React, { FunctionComponent } from 'react'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'

export const jobsLinkGroup: LinkGroup = {
    title: 'Jobs in deiner Nähe',
    links: [
        { name: 'Jobs in Wien', link: '/jobs/suche/wien?xtatc=INT-610' },
        { name: 'Jobs in Kärnten', link: '/jobs/suche/kaernten?xtatc=INT-615' },
        { name: 'Jobs in Niederösterreich', link: '/jobs/suche/niederoesterreich?xtatc=INT-611' },
        { name: 'Jobs in Tirol', link: '/jobs/suche/tirol?xtatc=INT-616' },
        { name: 'Jobs in Oberösterreich', link: '/jobs/suche/oberoesterreich?xtatc=INT-612' },
        { name: 'Jobs im Burgenland', link: '/jobs/suche/burgenland?xtatc=INT-617' },
        { name: 'Jobs in der Steiermark', link: '/jobs/suche/steiermark?xtatc=INT-613' },
        { name: 'Jobs in Vorarlberg', link: '/jobs/suche/vorarlberg?xtatc=INT-618' },
        { name: 'Jobs in Salzburg', link: '/jobs/suche/salzburg?xtatc=INT-614' },
        { name: 'Alle Jobs anzeigen', link: '/jobs/?xtatc=INT-519' },
    ],
}

export const JobsLinkVomit: FunctionComponent = () => {
    return <LinkVomit linkGroups={[jobsLinkGroup]} testId="jobs-link-vomit" />
}
