import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { BapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/BapAdvertSummaryComponent'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

export const AdSlider: FunctionComponent<{
    relativeSearchUrl: string
    displayTitle: string
    adsLimit?: number
    onClickAd?: (ad: AdvertSummary) => void
    onClickAllAdsText?: () => void
    onClickAllAdsButton?: () => void
    showMoreLink: string
}> = ({ relativeSearchUrl, displayTitle, onClickAd, onClickAllAdsText, onClickAllAdsButton, adsLimit = 9, showMoreLink }) => {
    const [searchResult, setSearchResult] = useState<SearchResult | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const ref = useRef<HTMLDivElement>(null)
    const isInView = useIsInView(ref)[0]

    useEffect(() => {
        async function loadSearchResult() {
            try {
                const result = await fetchFromContextLink<SearchResult>({
                    relativePath: relativeSearchUrl,
                    serviceName: 'iad',
                })
                setSearchResult(result)
            } catch (_error) {
                // silently ignore
            } finally {
                setIsLoading(false)
            }
        }

        if (isInView && relativeSearchUrl) {
            loadSearchResult()
        }
    }, [isInView, relativeSearchUrl])
    const advertSummaries = searchResult?.advertSummaryList.advertSummary.slice(0, adsLimit).map((advertSummary, __) => (
        <BapAdvertSummaryComponent
            key={advertSummary.id}
            advertSummary={advertSummary}
            onClickTaggingEvent={() => {
                onClickAd?.(advertSummary)
            }}
            variant="portrait"
        />
    ))

    if (!isLoading && !advertSummaries?.length) {
        return null
    }

    return (
        <Box paddingLeft={{ phone: 'm', tablet: 0 }} ref={ref}>
            {isLoading ? (
                <AdvertSummarySkeletons variant="portrait" />
            ) : (
                <HorizontalSlider
                    testIdPrefix="trends"
                    linkText="Alle anzeigen"
                    buttonText="Alle anzeigen"
                    onClick={onClickAllAdsText}
                    href={showMoreLink}
                    onClickButton={onClickAllAdsButton}
                    numberOfAdsToDisplay={adsLimit + 1} // +1 for the "show more" button
                    heading={<Heading level={2} text={displayTitle} fontSize={{ phone: 'l', tablet: 'xl' }} />}
                    variant="portrait"
                    forceShowButton={true}
                >
                    {advertSummaries?.map((a) => a) ?? []}
                </HorizontalSlider>
            )}
        </Box>
    )
}
