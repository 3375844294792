import { ContactAdvertiserRequest } from '@bbx/common/types/Messaging'
import { emailValidationMessage, optionalPhoneNumberValidationMessage, validationErrorMessages } from '@bbx/common/lib/validation'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { isNonEmpty } from '@wh/common/chapter/lib/validation'

export type BapSendEmailRequestFormData = Pick<ContactAdvertiserRequest, 'mailContent' | 'fromFullName' | 'from' | 'copyToSender'> & {
    telephone?: string
}

export const validateAdContactFormEmailBap =
    (contactSuggestionsAvailable: boolean) =>
    (values: BapSendEmailRequestFormData): Partial<Record<AdContactFormEmailBapField, string>> => {
        const isContactOptionOrMessageFilledOut = isNonEmpty(values.mailContent)

        const mailContentEmptyMessage = contactSuggestionsAvailable
            ? 'Bitte Antwortmöglichkeit wählen oder Kommentarfeld nutzen.'
            : 'Bitte das Nachrichtenfeld nutzen.'

        return removeUndefined({
            mailContent: !isContactOptionOrMessageFilledOut ? mailContentEmptyMessage : undefined,
            fromFullName: !isNonEmpty(values.fromFullName) ? validationErrorMessages.EMPTY_ERROR : undefined,
            from: emailValidationMessage(values.from),
            telephone: optionalPhoneNumberValidationMessage(values.telephone),
        })
    }

export type AdContactFormEmailBapField = keyof BapSendEmailRequestFormData

export const adContactFormEmailBapFieldToLabelMap: Record<AdContactFormEmailBapField, string> = {
    mailContent: '',
    fromFullName: 'Name',
    from: 'E-Mail-Adresse',
    telephone: 'Telefonnummer',
    copyToSender: 'Kopie dieser Anfrage an mich senden.',
}

export const adContactFormEmailBapFieldRequiredness = (
    _values: BapSendEmailRequestFormData,
): Record<AdContactFormEmailBapField, boolean> => {
    return {
        mailContent: false,
        fromFullName: true,
        telephone: false,
        from: true,
        copyToSender: false,
    }
}
