import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { isBuyNowAd, isPayliveryAd } from '@bbx/common/lib/isPayliveryAd'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { OfferButton } from '@bbx/lead-journey/lead-journey/components/OfferButton'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { PayliveryPriceBox } from '@bbx/lead-journey/lead-journey/components/ContactBox/PayliveryPriceBox'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { useProfileTrustSignals } from '@bbx/search-journey/common/lib/useProfileTrustSignals'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { Divider } from '@wh-components/core/Divider/Divider'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import React, { FunctionComponent, useMemo } from 'react'
import { NoliveryPriceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/ContactBox/NoliveryPriceBox'
import { getBapAdvertPrice } from '@bbx/search-journey/common/lib/getBapAdvertPrice'
import { PrivateProfile } from '../../../common/ContactBox/PrivateProfile'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'
import { SellerTypeInfoBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SellerTypeInfoBox/SellerTypeInfoBox'
import { BuyNowButton } from '@bbx/lead-journey/lead-journey/components/BuyNowButton/BuyNowButton'
import { StoryblokBox, StoryblokBoxType } from '@wh/common/cms/components/bloks/StoryblokBox'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'

interface BapContactBoxPrivateTopProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetails
    storyblokBannerBox: StoryblokBoxType | null
}

export const BapContactBoxPrivateTop: FunctionComponent<BapContactBoxPrivateTopProps> = ({
    advertDetails,
    storyblokBannerBox,
    ...props
}) => {
    const { name, contactName, image, userSince, address, phoneNumbers, companyName, activeAdCount, contactFirstName } = useMemo(
        () => getContactData(advertDetails),
        [advertDetails],
    )
    const { mainPrice } = useMemo(
        () => getBapAdvertPrice(advertDetails.adTypeId, advertDetails.advertStatus, advertDetails.attributes),
        [advertDetails],
    )
    const trustProfileLink = findContextLinkWithId('trustProfileLink', advertDetails.contextLinkList)
    const { payliverySales, presenceText, replyTime, reputation } = useProfileTrustSignals(trustProfileLink)

    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)
    const sellerProfileWebLink =
        organisationProfileWeblink ?? `/iad/kaufen-und-verkaufen/verkaeuferprofil/${advertDetails.organisationDetails.id}`
    const isBuyNow = isBuyNowAd(advertDetails)
    const optimizelyTrack = useOptimizelyTrack()

    return (
        <Card padding={0} testId="ad-detail-contact-box-private-top" {...props}>
            <Box display="flex" flexDirection="column" gap="xs" padding="m">
                {isPayliveryAd(advertDetails) ? (
                    <PayliveryPriceBox
                        mainPrice={mainPrice}
                        deliveryOptions={advertDetails.p2ppOptions?.deliveryOptions}
                        advertStatus={advertDetails.advertStatus}
                        testIdPrefix="contact-box"
                    />
                ) : (
                    <NoliveryPriceBox mainPrice={mainPrice} advertStatus={advertDetails.advertStatus} />
                )}
                <Box display={{ phone: 'none', tablet: 'block' }}>
                    {isBuyNow && (
                        <Box display="flex" flexDirection="column" marginTop="s">
                            <BuyNowButton
                                eventName="addetail_privatetop_buynow_click"
                                taggingData={advertDetails.taggingData}
                                adId={advertDetails.id}
                                testId="contact-box-buynow-button"
                            />
                        </Box>
                    )}

                    {isPayliveryAd(advertDetails) ? (
                        <Box display="flex" flexDirection="column" marginTop="s">
                            <OfferButton
                                adId={advertDetails.id}
                                variant={isBuyNow ? 'outline' : 'solid'}
                                eventName="addetail_privatetop_offer_click"
                                taggingData={advertDetails.taggingData}
                                testId="contact-box-offer-button"
                            />
                        </Box>
                    ) : (
                        <Divider marginTop="s" />
                    )}
                    <PrivateProfile
                        companyName={companyName}
                        paddingHorizontal="0"
                        name={contactName || name}
                        image={image}
                        userSince={userSince}
                        replyTime={replyTime}
                        testIdPrefix="top"
                        payliverySales={payliverySales}
                        presenceText={presenceText}
                        feedback={reputation?.feedback}
                        shouldShowPayLiveryRatings={true}
                    />
                    {address && <AddressBox address={address} testIdPrefix="top" />}
                    <PhoneNumberButton
                        phoneNumbers={phoneNumbers}
                        toggleWithContext={true}
                        adId={advertDetails.id}
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="top"
                        variant="link"
                        marginTop="xs"
                    />
                    <Box display="flex" flexDirection="column" gap="s" paddingTop="m">
                        <ContactButton
                            contactType={advertDetails.contactOption.contactType}
                            taggingData={advertDetails.taggingData}
                            adStatus={advertDetails.advertStatus}
                            variant={isPayliveryAd(advertDetails) ? 'outline' : undefined}
                            optimizelyTrackEvent="bbx_button_contact_seller_desktop"
                        >
                            Nachricht
                        </ContactButton>

                        <SellerInfoButton
                            link={sellerProfileWebLink}
                            activeAdCount={activeAdCount}
                            clickTaggingActionEvent="addetail_more_ads_click"
                            taggingData={advertDetails.taggingData}
                            testIdPrefix="top"
                            onClick={() => optimizelyTrack('bbx_top_contact_box_click_more_ads_from_seller')}
                        >
                            Weitere Anzeigen von {contactFirstName ?? 'diesem Verkäufer'}
                        </SellerInfoButton>
                        <SellerTypeInfoBox attributes={advertDetails.attributes} />
                    </Box>
                    {storyblokBannerBox && <StoryblokBox blok={storyblokBannerBox} />}
                </Box>
            </Box>
        </Card>
    )
}
