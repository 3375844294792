import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import sustainability_tree from '@bbx/static_hashed/img/sustainability_tree/sustainability_tree.svg'
import { AdvertDetails, BreadcrumbsItem } from '@bbx/common/types/ad-detail/AdvertDetails'
import { isNumberString } from '@wh/common/chapter/lib/validation'
import { getSellerType, SellerType } from '@bbx/common/lib/getSellerType'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import ArrowRightIcon from '@wh-components/icons/ArrowRight'
import { css } from 'styled-components'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { palette } from '@wh-components/core/theme/palette'

interface BapSustainabilityInfo {
    advertDetails: AdvertDetails
}

export const BapSustainabilityInfo: FunctionComponent<BapSustainabilityInfo & SpaceProps> = ({ advertDetails }) => {
    const message = getMessage(advertDetails)
    if (!message) {
        return null
    }

    return (
        <ServerRoutingAnchorLink
            href="/will/nachhaltigkeit"
            type="anchor"
            onClick={() => {
                callActionEvent('addetail_sustainiability_box_info_link_click', undefined, {
                    category: getMainCategoryLabel(advertDetails),
                })
            }}
            css={css`
                &:hover {
                    text-decoration: none;
                }
            `}
        >
            <Box
                display="flex"
                flexDirection="row"
                backgroundColor="#ECFBF8"
                borderRadius="m"
                paddingVertical="s"
                paddingHorizontal="m"
                marginHorizontal={{ phone: 'm', tablet: 0 }}
            >
                <Box display="flex" justifyContent="center" marginRight="s">
                    <ResponsiveImage src={sustainability_tree} aria-hidden={true} />
                </Box>
                <Box>
                    <Box>
                        <Text fontWeight="bold" color="#4DB963">
                            Will Danke sagen
                        </Text>
                    </Box>
                    <Text color={palette.verydarkgrey}>{message}</Text>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ArrowRightIcon
                        size="xsmall"
                        aria-hidden={true}
                        marginLeft="s"
                        css={css`
                            color: ${(p) => p.theme.colors.palette.elephant};
                        `}
                    />
                </Box>
            </Box>
        </ServerRoutingAnchorLink>
    )
}

const messages: Record<string, string> = {
    '3928': 'Pre-loved Kinder-Artikel sparen bis zu 14.516 Tonnen CO₂e im Jahr. Das ist mehr, als 2.400 Hektar Wald speichern können! ',
    '5824': 'Pre-loved Computer-Artikel sparen bis zu 15.105 Tonnen CO₂e im Jahr. Das ist mehr, als 2.500 Hektar Wald speichern können!  ',
    '3541': 'Pre-loved Haus / Garten / Werkstatt Artikel sparen bis zu 36.648 Tonnen CO₂e im Jahr. Das ist mehr, als 6.100 Hektar Wald speichern können!  ',
    '6808': 'Pre-loved Kamera / TV / Multimedia Artikel sparen bis zu 3.698 Tonnen CO₂e im Jahr. Das ist mehr, als 610 Hektar Wald speichern können! ',
    '3275': 'Pre-loved Mode / Accessoires sparen bis zu 5.116 Tonnen CO₂e im Jahr. Das ist mehr, als 850 Hektar Wald speichern können! ',
    '2691': 'Pre-loved Smartphones & Co sparen bis zu 3.070 Tonnen CO₂e im Jahr. Das ist mehr, als 510 Hektar Wald speichern können! ',
    '5136': 'Pre-loved Spiele / Spielzeuge sparen bis zu 1.237 Tonnen CO₂e im Jahr. Das ist mehr, als 200 Hektar Wald speichern können! ',
    '4390': 'Pre-loved Sportartikel sparen bis zu 12.388 Tonnen CO₂e im Jahr. Das ist mehr, als 2.000 Hektar Wald speichern können! ',
    '5387': 'Pre-loved Möbel & Co sparen bis zu 64.792 Tonnen CO₂e im Jahr. Das ist mehr, als 10.700 Hektar Wald speichern können! ',
}
const excludeCategoryIds = ['4759', '4758', '4307', '3468', '3382', '3988', '4068', '4135', '5762', '5891']

const getMessage = (advertDetails: AdvertDetails) => {
    const adCategoryIds = getCategoryIdsFromBreadcrumbs(advertDetails.breadcrumbs ?? [])

    const visible = adCategoryIds && isVisible(advertDetails, adCategoryIds)
    if (!visible) {
        return undefined
    }

    return adCategoryIds && messages[adCategoryIds[0]] !== undefined ? messages[adCategoryIds[0]] : undefined
}

const getCategoryIdsFromBreadcrumbs = (breadcrumbs: BreadcrumbsItem[]) => {
    return (
        breadcrumbs
            .filter((breadcrumb) => breadcrumb.seoUrl.includes('-'))
            .map((breadcrumb) => breadcrumb.seoUrl.split('-'))
            .map((breadcrumbSplitted) => breadcrumbSplitted[breadcrumbSplitted.length - 1])
            .filter((value) => isNumberString(value)) ?? []
    )
}

const isVisible = (advertDetails: AdvertDetails, adCategoryIds: string[]) => {
    if (!adCategoryIds) {
        return false
    }

    const allowedCategoryIds = Object.keys(messages)
    const isCategoryIdAllowed = adCategoryIds.some((adCategoryId) => allowedCategoryIds.includes(adCategoryId))

    const isCategoryIdExcluded = adCategoryIds.some((adCategoryId) => excludeCategoryIds.includes(adCategoryId))

    const condition = advertDetails.attributeInformation?.filter((attribute) => 'Zustand' === attribute.treeAttributeElement.label)
    const isNew = condition?.[0]?.values.some((value) => 'Neu' === value.label)

    const isPrivate = getSellerType(advertDetails.attributes) === SellerType.PRIVATE

    return isCategoryIdAllowed && !isCategoryIdExcluded && !isNew && isPrivate
}

const getMainCategoryLabel = (advertDetails: AdvertDetails): string | undefined => {
    return advertDetails.breadcrumbs?.filter((breadcrumb) => /-\d+$/.test(breadcrumb.seoUrl))?.[0].displayName
}
