import React, { FunctionComponent } from 'react'
import { RecommendationWidgetType } from '@bbx/common/types/UserFeed'
import { RecommendationsWidgetSlider } from '@bbx/search-journey/common/components/Widgets/RecommendationsWidgetSlider'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { TaggingWidgetType, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'

interface AdDetailRecommendationsWidgetProps {
    recommendationWidget: RecommendationWidgetType
    taggingData: TaggingData
    taggingPrefix?: string
    taggingWidgetType?: TaggingWidgetType
}

export const AdDetailRecommendationsWidget: FunctionComponent<AdDetailRecommendationsWidgetProps> = ({
    recommendationWidget,
    taggingData,
    taggingWidgetType = 'RecommendationWidget',
}) => {
    const numberOfAdsLimit = 9
    const widgetAndPage = {
        taggingWidgetType: taggingWidgetType,
        page: 'AdDetail',
        source: 'ad-view-detail',
    }
    const { visibilityTrackingRef } = useWidgetTagging({
        ...widgetAndPage,
        adIds: recommendationWidget.ads.map((ad) => ad.id).slice(0, numberOfAdsLimit),
        pulseMetadata: recommendationWidget.pulseMetadata,
    })

    const trackEvent = useOptimizelyTrack(undefined, { page: 'addetail' })

    return (
        <RecommendationsWidgetSlider
            widget={recommendationWidget}
            paddingLeft={{ phone: 'm', tablet: 0 }}
            onClickAdTagging={(ad, index) => {
                trackEvent('item-recommendationsInteraction')
                trackEvent('item-recommendationsAdClick')
                callActionEvent('recommendation_widget_ad_click', taggingData, {
                    ...widgetAndPage,
                    adId: ad.id,
                    adIndex: index + 1,
                    listName: recommendationWidget.pulseMetadata?.listName,
                    transactionId: recommendationWidget.pulseMetadata?.transactionId,
                })
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
            }}
            onClickShowAllTagging={() => {
                trackEvent('item-recommendationsInteraction')
                trackEvent('item-recommendationsShowMoreClick')
                callActionEvent('recommendation_widget_show_all_click', taggingData, {
                    ...widgetAndPage,
                    listName: recommendationWidget.pulseMetadata?.listName,
                    transactionId: recommendationWidget.pulseMetadata?.transactionId,
                    widgetInteractionId: location.href,
                    forcePulse: true,
                })
                callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
            }}
            onClickShowAllButtonTagging={() => {
                trackEvent('item-recommendationsInteraction')
                trackEvent('item-recommendationsShowMoreClick')
                callActionEvent('recommendation_widget_show_all_button_click', taggingData, {
                    ...widgetAndPage,
                    listName: recommendationWidget.pulseMetadata?.listName,
                    transactionId: recommendationWidget.pulseMetadata?.transactionId,
                    widgetInteractionId: location.href,
                    forcePulse: true,
                })
                callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
            }}
            onClickArrowTagging={() => {
                callActionEvent('recommendation_widget_arrow_click', taggingData, {
                    ...widgetAndPage,
                    listName: recommendationWidget.pulseMetadata?.listName,
                    transactionId: recommendationWidget.pulseMetadata?.transactionId,
                    widgetInteractionId: location.href,
                    forcePulse: true,
                })
            }}
            visibilityTrackingRef={visibilityTrackingRef}
            numberOfAdsLimit={numberOfAdsLimit}
        />
    )
}
