import React, { FunctionComponent } from 'react'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { Text } from '@wh-components/core/Text/Text'
import { SpaceProps } from '@wh-components/system/space'
import { Price } from '@bbx/common/types/Price'
import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'

interface PriceStatusLabelProps extends SpaceProps {
    mainPrice: Price
    advertStatus: AdvertStatus
}

export const PriceStatusLabel: FunctionComponent<PriceStatusLabelProps> = ({ mainPrice, advertStatus }) => {
    if (!mainPrice.label) {
        return null
    }

    switch (advertStatus.id) {
        case adStatusFlag.SOLD:
            return (
                <Text fontSize="s" fontWeight="bold" color="palette.elephant" testId="contact-box-price-box-price-label">
                    {mainPrice.label}
                </Text>
            )
        case adStatusFlag.RESERVED:
            return (
                <Text fontSize="s" fontWeight="bold" color="palette.primary.main" testId="contact-box-price-box-price-label">
                    RESERVIERT
                </Text>
            )
        default:
            return (
                <Text fontSize="s" color="palette.raccoon" testId="contact-box-price-box-price-label">
                    {mainPrice.label}
                </Text>
            )
    }
}
