import { AttributeGroup, FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'
import React, { FunctionComponent } from 'react'
import { AttributesBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AttributesBox/AttributesBox'
import { SpaceProps } from '@wh-components/system/space'
import { AttributeInformation } from '@bbx/common/types/ad-detail/AdvertDetails'

interface BapAttributesBoxProps extends SpaceProps {
    attributeInformation: AttributeInformation[] | null
}

export const BapAttributesBox: FunctionComponent<BapAttributesBoxProps> = ({ attributeInformation, ...props }) => {
    if (!attributeInformation) {
        return null
    }
    const groupedAttributes = [groupAttributeInformation(attributeInformation)]
    return (
        <AttributesBox
            groupedAttributes={groupedAttributes}
            title="Detailinformationen"
            displayHeading={{ phone: 'none', tablet: 'inline' }}
            {...props}
        />
    )
}

export const groupAttributeInformation = (attributeInformation: AttributeInformation[]): AttributeGroup => {
    const items: FormattedAttributesItem[] = attributeInformation
        .sort((a, b) => a.treeAttributeElement.sortOrder - b.treeAttributeElement.sortOrder)
        .map((attr) => ({
            name: 'BAP_GENERIC',
            values: [],
            formattedValues: [attr.values.map((att) => att.label).join(', ')],
            description: attr.treeAttributeElement.label,
        }))
    return {
        id: '',
        heading: '',
        attribute: items,
    }
}
