/* eslint-disable max-lines */
import React, { Fragment, FunctionComponent, ReactElement, useMemo, useRef, useState } from 'react'
import { Breadcrumbs } from '@bbx/search-journey/common/components/Breadcrumbs/Breadcrumbs'
import { AdvertDetails, AttributeInformation, isContactPossible, SellerProfileUserData } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Box } from '@wh-components/core/Box/Box'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { FullWidthRow } from '@wh/common/chapter/components/Layouts/rows/FullWidthRow'
import { DaPromotionWidget } from '@bbx/search-journey/sub-domains/ad-detail/components/common/DaWidget/DaWidget'
import { PreviousBackNext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/PreviousBackNext/PreviousBackNext'
import {
    ReportAdBox,
    ReportAdButton,
    WhCodeAndLastChangedDate,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/ReportAdBox/ReportAdBox'
import { TRow } from '@wh/common/chapter/components/Layouts/rows/TRow'
import { TwoColumnRow } from '@wh/common/chapter/components/Layouts/rows/TwoColumnRow'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { findContextLinkWithId, SimilarAdsContextLink } from '@bbx/common/types/contextLinks'
import { getAttribute } from '@wh/common/chapter/types/Attributes'
import { AdHeader } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdHeader/AdHeader'
import { PhoneNumberContext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberContext'
import { AdDetailSavedAdButtonContext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SaveAdButton/AdDetailSavedAdButtonContext'
import { SellerType } from '@bbx/common/lib/getSellerType'
import { useAdDetailDigitalAdvertisingBap } from '@bbx/common/digital-advertising/hooks/useAdDetailDigitalAdvertising'
import { DmpStateProvider } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { BapContactBoxDealerTop } from './ContactBox/BapContactBoxDealerTop'
import { BapContactBoxPrivateTop } from './ContactBox/BapContactBoxPrivateTop'
import { BapContactBoxDealerBottom } from './ContactBox/BapContactBoxDealerBottom'
import { BapContactBoxPrivateBottom } from './ContactBox/BapContactBoxPrivateBottom'
import { AdDetailLargeRenderSlot } from '@wh/common/digital-advertising/components/AdDetailLargeRenderSlot/AdDetailLargeRenderSlot'
import { AdDetailMediumRenderSlot } from '@wh/common/digital-advertising/components/AdDetailMediumRenderSlot/AdDetailMediumRenderSlot'
import { AdDetailSmallRenderSlot } from '@wh/common/digital-advertising/components/AdDetailSmallRenderSlot/AdDetailSmallRenderSlot'
import { SaveAdModal, SavedAdsUiState } from '@bbx/search-journey/common/components/SaveAd/SaveAdModal'
import { printDisplayBlockFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printDisplayBlockFirefoxWorkaroundCss'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { emptyHidingCss } from '@wh/common/digital-advertising/components/emptyHidingCss'
import { inverseVerticalIdMap, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { ImageGalleryData } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery.settings'
import { AdvertImage } from '@wh/common/chapter/types/AdvertImage'
import { BapAttributesBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/AttributesBox/BapAttributesBox'
import { BapSimilarAds } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/SimilarAds/BapSimilarAds'
import { BapAdContactForm } from '@bbx/lead-journey/lead-journey/components/AdContactForm/bap/BapAdContactForm'
import { BapSecurityHints } from './SecurityHints/BapSecurityHints'
import { BapDescriptionBox } from './DescriptionBox/BapDescriptionBox'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { SearchTermsLinkVomit } from '@bbx/search-journey/common/components/SearchTermsLinkVomit/SearchTermsLinkVomit'
import { Divider } from '@wh-components/core/Divider/Divider'
import { BapServiceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/ServiceBox/BapServiceBox'
import { BapLocationTeaser } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/LocationTeaser/BapLocationTeaser'
import { BapAdDetailStickyHeader } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/StickyHeader/BapAdDetailStickyHeader'
import { JobsLinkVomit } from '@bbx/search-journey/common/components/JobsLinkVomit/JobsLinkVomit'
import { CallAndContactAdDetailStickyFooter } from '../../common/StickyFooter/CallAndContactAdDetailStickyFooter'
import { isPayliveryAd } from '@bbx/common/lib/isPayliveryAd'
import { BapMakeOfferAndBuyNowAdDetailStickyFooter } from './StickyFooter/BapMakeOfferAndBuyNowAdDetailStickyFooter'
import { CallAndOrderNowAdDetailStickyFooter } from './StickyFooter/CallAndOrderNowAdDetailStickyFooter'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { AnimalSecurityHints } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/SecurityHints/AnimalSecurityHints'
import { getBapAdvertPrice } from '@bbx/search-journey/common/lib/getBapAdvertPrice'
import { MoreInfoLinksBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/MoreInfoLinksBox/MoreInfoLinksBox'
import { ImageGallery } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery'
import { SimilarAdsButton } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/ImageGallery/SimilarAdsButton'
import { PriceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PriceBox'
import { useOptimizelyDecision, useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { useLoadRecommendationsWidget } from '@bbx/search-journey/common/lib/useLoadRecommendationsWidget'
import { AdDetailRecommendationsWidget } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/RecommendationsWidgetWrapper/AdDetailRecommendationsWidget'
import { useLastViewedAds } from '@bbx/search-journey/common/lib/useLastViewedAds'
import { useLoadLastViewedAdsWidget } from '@bbx/search-journey/common/lib/useLoadLastViewedAdsWidget'
import { AdDetailLastViewedAdsWidget } from '@bbx/search-journey/sub-domains/ad-detail/components/common/LastViewedAdsWidgetWrapper/AdDetailLastViewedAdsWidget'
import { BumpedBadgeText } from '../../common/BumpedBadgeText/BumpedBadgeText'
import { LegalDisclaimerInfoBox } from '../../common/LegalDisclaimerInfoBox/LegalDisclaimerInfoBox'
import { BapSimilarAdsEndless } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/SimilarAds/BapSimilarAdsEndless'
import { StoryblokBoxType } from '@wh/common/cms/components/bloks/StoryblokBox'
import { BapSustainabilityInfo } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/EnvironmentBox/BapSustainabilityInfo'
import { AdSlider } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdSlider'
import { OrganisationDetails } from '@bbx/common/types/ad-detail/OrganisationDetails'

interface BapAdDetailContainerProps {
    advertDetails: AdvertDetails
    sellerType: SellerType
    searchTerms: SeoSearchTerms[]
    profileData: UserProfileData | undefined
    storyblokPrivateContactBoxBannerBox: StoryblokBoxType | null
}

const sizeAttrList = [
    'CLOTHING_SIZE_FEMALE',
    'CLOTHING_SIZE_MALE',
    'CLOTHING_SIZE_KIDS',
    'CLOTHING_SIZE_MOTOR_CYCLE',
    'CLOTHING_SIZE_SPORT',
    'HELMET_SIZE',
    'HELMET_SIZE_KIDS',
    'HELMET_SIZE_MOTOR_CYCLE',
    'SHOE_SIZE',
    'SHOE_SIZE_FEMALE',
    'SHOE_SIZE_MALE',
    'SHOE_SIZE_KIDS',
    'SHOE_SIZE_MOTOR_CYLCE',
    'SHOE_SIZE_SPORT',
    'TROUSER_WIDTH_FEMALE',
    'TROUSER_WIDTH_MALE',
]

export const BapAdDetailContainer: FunctionComponent<BapAdDetailContainerProps> = ({
    advertDetails,
    sellerType,
    searchTerms,
    profileData,
    storyblokPrivateContactBoxBannerBox,
}) => {
    const [isSavedInFolder, setIsSavedInFolder] = useState(advertDetails.savedInFolder)
    const [savedAdsUiState, setSavedAdsUiState] = useState<SavedAdsUiState>({ state: 'idle' })
    const [telephoneVisible, setTelephoneVisible] = useState(false)

    const imageData: ImageGalleryData[] = getImageData(advertDetails)
    const stickyHeaderVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const adContactVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const securityHintsRef = useRef<HTMLAnchorElement>(null)
    const trackEvent = useOptimizelyTrack()
    const { trackCustomDmpEvent, setAdvertisingStateContacted } = useAdDetailDigitalAdvertisingBap(advertDetails)

    const { mainPrice } = getBapAdvertPrice(advertDetails.adTypeId, advertDetails.advertStatus, advertDetails.attributes)
    const similarAdsContextLink = findContextLinkWithId('similarAdsRecommendationUrl', advertDetails.contextLinkList)
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(advertDetails), [advertDetails])

    const recommendationsWidget = useLoadRecommendationsWidget(securityHintsRef)
    const lastViewedAdsWidget = useLoadLastViewedAdsWidget(verticalIdMap.BAP, Number(advertDetails.id), securityHintsRef)
    useLastViewedAds(Number(advertDetails.id), verticalIdMap.BAP)

    const sellerEligibleForMoreAdsFromSeller =
        advertDetails.sellerProfileUserData.private && advertDetails.sellerProfileUserData.activeAdCount >= 6
    const enabledCategoryForMoreAdsFromSeller = !!advertDetails.breadcrumbs?.filter(
        (cat) => cat.seoUrl.endsWith('-3275') || cat.seoUrl.endsWith('-3929'),
    )?.length
    const adEligibleForMoreAdsFromSeller = sellerEligibleForMoreAdsFromSeller && enabledCategoryForMoreAdsFromSeller

    const similarAds = similarAdsContextLink && (
        <FullWidthRow order={6} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
            <BapSimilarAds
                similarAdsRecommendationContextLink={similarAdsContextLink as SimilarAdsContextLink}
                taggingData={advertDetails.taggingData}
            />
        </FullWidthRow>
    )
    return (
        <DmpStateProvider trackCustomDmpEvent={trackCustomDmpEvent}>
            <AdDetailSavedAdButtonContext.Provider value={[isSavedInFolder, setIsSavedInFolder]}>
                <PhoneNumberContext.Provider value={[telephoneVisible, setTelephoneVisible, setAdvertisingStateContacted]}>
                    <SaveAdModal
                        savedAdsUiState={savedAdsUiState}
                        setSavedAdsUiState={setSavedAdsUiState}
                        onSavedAd={(adId) => {
                            if (adId === advertDetails.id) {
                                setIsSavedInFolder(true)
                            }
                        }}
                        loginId={profileData?.loginId}
                        onSavedTaggingEvent={() => callActionEvent('addetail_favorite_ad_saved', advertDetails.taggingData)}
                    />
                    <Box as="article" display="flex" flexDirection="column" css={printDisplayBlockFirefoxWorkaroundCss}>
                        <BapAdDetailStickyHeader
                            advertDetails={advertDetails}
                            sellerType={sellerType}
                            stickyHeaderVisibilityTrackingRef={stickyHeaderVisibilityTrackingRef}
                            price={mainPrice.value}
                            priceDescription={mainPrice.label}
                        />
                        {isPayliveryAd(advertDetails) ? (
                            <BapMakeOfferAndBuyNowAdDetailStickyFooter
                                advertDetails={advertDetails}
                                stickyFooterVisibilityTrackingRef={adContactVisibilityTrackingRef}
                            />
                        ) : contactTypeWithDetails.type === 'EXTERNAL' ? (
                            <CallAndOrderNowAdDetailStickyFooter
                                advertDetails={advertDetails}
                                advertContactDetails={advertDetails.advertContactDetails}
                                contactOption={advertDetails.contactOption}
                                stickyFooterVisibilityTrackingRef={adContactVisibilityTrackingRef}
                                taggingData={advertDetails.taggingData}
                            />
                        ) : (
                            isContactPossible(advertDetails.advertStatus) && (
                                <CallAndContactAdDetailStickyFooter
                                    contactOption={advertDetails.contactOption}
                                    adId={advertDetails.id}
                                    adStatus={advertDetails.advertStatus}
                                    advertContactDetails={advertDetails.advertContactDetails}
                                    taggingData={advertDetails.taggingData}
                                    stickyFooterVisibilityTrackingRef={adContactVisibilityTrackingRef}
                                />
                            )
                        )}
                        <TwoColumnRow
                            order={0}
                            display={{ phone: 'none', tablet: 'flex', print: 'none' }}
                            marginBottom="sm"
                            left={<Breadcrumbs breadcrumbsList={advertDetails.breadcrumbs} />}
                            right={<PreviousBackNext adId={advertDetails.id} taggingData={advertDetails.taggingData} />}
                        />

                        <FullWidthRow order={0} display={{ phone: 'block', tablet: 'none' }} marginBottom="0">
                            <PreviousBackNext adId={advertDetails.id} taggingData={advertDetails.taggingData} />
                        </FullWidthRow>

                        <TRow
                            order={1}
                            internalOrder={{
                                posTop: { phone: 1, tablet: 0 },
                                posLeft: { phone: 0, tablet: 1 },
                                posRight: { phone: 2 },
                            }}
                            top={
                                <AdHeader
                                    advertDetails={advertDetails}
                                    locationTeaser={<BapLocationTeaser advertDetails={advertDetails} />}
                                />
                            }
                            left={
                                <Box ref={stickyHeaderVisibilityTrackingRef} height="100%">
                                    <ImageGallery
                                        // setting the key property forces an unmount of the component when the key changes (used for client-side routing)
                                        key={advertDetails.id}
                                        height={{ phone: '75vw', tablet: '466px' }}
                                        vertical={inverseVerticalIdMap[advertDetails.verticalId]}
                                        adType={inverseAdTypeIdMap[advertDetails.adTypeId]}
                                        imageData={imageData}
                                        carouselOverlay={(currentIndex) => (
                                            <SimilarAdsButton
                                                similarImageSearchUrl={imageData[currentIndex].similarImageSearchUrl}
                                                taggingData={advertDetails.taggingData}
                                            />
                                        )}
                                    />
                                    <AnimalSecurityHints categoryId={advertDetails.categoryTreeId} />
                                </Box>
                            }
                            right={
                                sellerType === 'dealer' ? (
                                    <BapContactBoxDealerTop advertDetails={advertDetails} height="100%" />
                                ) : (
                                    <BapContactBoxPrivateTop
                                        advertDetails={advertDetails}
                                        storyblokBannerBox={storyblokPrivateContactBoxBannerBox}
                                        height="100%"
                                    />
                                )
                            }
                            marginBottom={{ phone: 'm', tablet: 'l' }}
                        />

                        <TwoColumnRow
                            order={2}
                            marginBottom={{ phone: 'm', tablet: 'l' }}
                            separator={true}
                            left={
                                <Box display="flex" flexDirection="column" gap="m" css={printDisplayBlockFirefoxWorkaroundCss}>
                                    <BapAttributesBox
                                        attributeInformation={advertDetails.attributeInformation}
                                        paddingHorizontal={{ phone: 'm', tablet: 0 }}
                                    />

                                    <BapSustainabilityInfo advertDetails={advertDetails} />
                                    <BapDescriptionBox advertDetails={advertDetails} paddingHorizontal={{ phone: 'm', tablet: 0 }} />
                                    <MoreInfoLinksBox advertDetails={advertDetails} paddingHorizontal={{ phone: 'm', tablet: 0 }} />
                                    {sellerType !== 'dealer' && (
                                        <BapServiceBox serviceBoxGroups={advertDetails.serviceBoxGroups} marginBottom="m" />
                                    )}
                                    <LegalDisclaimerInfoBox advertDetails={advertDetails} />
                                    <BumpedBadgeText advertDetails={advertDetails} />
                                </Box>
                            }
                            right={
                                <Box display="flex" flexDirection="column" gap="m">
                                    <AdDetailLargeRenderSlot css={emptyHidingCss} />
                                    <AdDetailMediumRenderSlot css={emptyHidingCss} />
                                    <AdDetailSmallRenderSlot css={emptyHidingCss} />

                                    {advertDetails.daWidget && (
                                        <DaPromotionWidget
                                            price={getAttribute(advertDetails.attributes.attribute, 'PRICE')}
                                            daWidget={advertDetails.daWidget}
                                        />
                                    )}
                                    {sellerType === 'dealer' && (
                                        <BapServiceBox serviceBoxGroups={advertDetails.serviceBoxGroups} showInColumns={true} />
                                    )}
                                </Box>
                            }
                        />

                        {adEligibleForMoreAdsFromSeller ? (
                            <ExperimentalAdSlider
                                experiment="bbx_other_ads_from_user"
                                attributeInformation={advertDetails.attributeInformation}
                                organisation={advertDetails.organisationDetails}
                                sellerProfile={advertDetails.sellerProfileUserData}
                                ifEmpty={similarAds}
                            />
                        ) : (
                            similarAds
                        )}

                        <FullWidthRow order={7} marginBottom={{ tablet: 's' }}>
                            <AdBoxHeading
                                text={sellerType === 'dealer' ? 'Anbieterdetails' : 'Verkäuferdetails'}
                                paddingHorizontal={{ phone: 'm', tablet: 0 }}
                            />
                        </FullWidthRow>

                        <TwoColumnRow
                            order={8}
                            layout="300px 1fr"
                            left={
                                sellerType === 'dealer' ? (
                                    <BapContactBoxDealerBottom advertDetails={advertDetails} />
                                ) : (
                                    <BapContactBoxPrivateBottom advertDetails={advertDetails} />
                                )
                            }
                            right={
                                <Fragment>
                                    <BapAdContactForm
                                        setAdvertisingStateContacted={() => {
                                            setAdvertisingStateContacted()
                                            trackEvent('bap_lead')
                                        }}
                                        advertDetails={advertDetails}
                                        profileData={profileData}
                                        adContactVisibilityTrackingRef={adContactVisibilityTrackingRef}
                                        mainPrice={(advertDetails2) =>
                                            getBapAdvertPrice(
                                                advertDetails2.adTypeId,
                                                advertDetails2.advertStatus,
                                                advertDetails2.attributes,
                                            ).mainPrice
                                        }
                                        priceBox={(advertDetails2) => (
                                            <PriceBox
                                                mainPrice={
                                                    getBapAdvertPrice(
                                                        advertDetails2.adTypeId,
                                                        advertDetails2.advertStatus,
                                                        advertDetails2.attributes,
                                                    ).mainPrice
                                                }
                                            />
                                        )}
                                    />
                                </Fragment>
                            }
                        />
                        <FullWidthRow order={9}>
                            <ReportAdBox>
                                <WhCodeAndLastChangedDate adId={advertDetails.id} changedDate={advertDetails.changedDate} />
                                <ReportAdButton adId={advertDetails.id} />
                            </ReportAdBox>
                        </FullWidthRow>
                        <FullWidthRow order={10} display={{ phone: 'block', tablet: 'none' }}>
                            <Box paddingHorizontal={{ phone: 'm', tablet: 0 }}>
                                <Breadcrumbs breadcrumbsList={advertDetails.breadcrumbs} hideJsonLd={true} />
                            </Box>
                        </FullWidthRow>

                        {recommendationsWidget && recommendationsWidget.ads?.length > 0 && (
                            <FullWidthRow order={11} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
                                <AdDetailRecommendationsWidget
                                    recommendationWidget={recommendationsWidget}
                                    taggingData={advertDetails.taggingData}
                                />
                            </FullWidthRow>
                        )}

                        {lastViewedAdsWidget && lastViewedAdsWidget.ads?.length > 0 && (
                            <FullWidthRow order={12} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
                                <AdDetailLastViewedAdsWidget
                                    lastViewedAdsSlider={lastViewedAdsWidget}
                                    taggingData={advertDetails.taggingData}
                                />
                            </FullWidthRow>
                        )}

                        <FullWidthRow order={13} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
                            <BapSecurityHints categoryId={advertDetails.categoryTreeId} securityHintsRef={securityHintsRef} />
                        </FullWidthRow>

                        {similarAdsContextLink && (
                            <FullWidthRow order={14} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
                                <BapSimilarAdsEndless
                                    similarAdsRecommendationContextLink={similarAdsContextLink as SimilarAdsContextLink}
                                    taggingData={advertDetails.taggingData}
                                />
                            </FullWidthRow>
                        )}

                        {searchTerms && (
                            <FullWidthRow order={15} display={{ print: 'none' }} marginBottom={{ tablet: 'm' }}>
                                <Divider display={{ phone: 'block', tablet: 'none' }} />
                                <SearchTermsLinkVomit searchTermsList={searchTerms} />
                            </FullWidthRow>
                        )}

                        <FullWidthRow order={16} display={{ print: 'none' }} marginBottom={{ phone: 'm', tablet: 0 }}>
                            <Divider display={{ phone: 'none', tablet: 'block' }} marginBottom={{ tablet: 'm' }} />
                            <JobsLinkVomit />
                        </FullWidthRow>
                    </Box>
                </PhoneNumberContext.Provider>
            </AdDetailSavedAdButtonContext.Provider>
        </DmpStateProvider>
    )
}

/**
 * After this is removed as an experiment and added "for real" this component should probably be removed (or completely re-written) since it provides little value
 * and only exists to make useOptimizely conditional
 */
const ExperimentalAdSlider: FunctionComponent<{
    experiment: string
    attributeInformation: AttributeInformation[] | null
    organisation: OrganisationDetails
    sellerProfile: SellerProfileUserData
    ifEmpty: ReactElement | undefined
}> = ({ experiment, attributeInformation, organisation, sellerProfile, ifEmpty }) => {
    const otherAdsFromUserExperiment = useOptimizelyDecision(experiment)
    const trackOptimizelyEvent = useOptimizelyTrack()
    const adsFromSellerContextLink = useMemo(() => {
        const boostSimilar = otherAdsFromUserExperiment.variationKey === 'boosted_size'
        const filterByOrgId = boostSimilar || otherAdsFromUserExperiment.variationKey === 'on'
        if (!filterByOrgId) return null
        const boostedSize =
            boostSimilar &&
            attributeInformation?.filter(
                (attr) => attr.treeAttributeElement.code && sizeAttrList.includes(attr.treeAttributeElement.code),
            )[0]?.values[0]?.treeId
        return {
            displayText: `Weitere Anzeigen von ${organisation?.orgName ?? sellerProfile.name}`,
            relativePath: `/search/atz/5/301?orgId=${organisation.id}&sort=7${boostedSize ? `&b_treeAttributes=${boostedSize}` : ''}`,
        }
    }, [otherAdsFromUserExperiment.variationKey, organisation, sellerProfile, attributeInformation])
    return adsFromSellerContextLink ? (
        <FullWidthRow order={6} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
            <AdSlider
                displayTitle={adsFromSellerContextLink.displayText}
                relativeSearchUrl={adsFromSellerContextLink.relativePath}
                showMoreLink={`/iad/kaufen-und-verkaufen/verkaeuferprofil/${organisation.id}`}
                adsLimit={4}
                onClickAd={() => trackOptimizelyEvent('bbx_user_click_other_ad_from_user')}
                onClickAllAdsButton={() => trackOptimizelyEvent('bbx_user_click_all_other_ads_button_from_user')}
                onClickAllAdsText={() => trackOptimizelyEvent('bbx_user_click_all_other_ads_from_user')}
            />
        </FullWidthRow>
    ) : (
        ifEmpty
    )
}

const getImageData = (advertDetails: AdvertDetails) => {
    return advertDetails.advertImageList.advertImage.map((advertImage: AdvertImage, index) => ({
        alt: `Bild ${index + 1} von ${advertDetails.advertImageList.advertImage.length}${
            advertImage.description ? ` - ${advertImage.description}` : ''
        }`,
        imageUrl: advertImage.referenceImageUrl,
        thumbnailUrl: advertImage.thumbnailImageUrl,
        similarImageSearchUrl: advertImage.similarImageSearchUrl,
    }))
}
