import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { BapJsonLd } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/JsonLD/BapJsonLd'
import { NextPage } from 'next'
import { adDetailGetInitialProps } from 'pages/iad/gebrauchtwagen/d/[[...seopath]]'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { AdIdNotFoundAlert } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdIdNotFoundAlert/AdIdNotFoundAlert'
import { BapAdDetailContainer } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/BapAdDetailContainer'
import { ExpressNextContextWithUserData, OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { getSeoSearchTerms } from '@bbx/search-journey/common/api/searchApiClient'
import { BapAdDetailUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { getSellerType } from '@bbx/common/lib/getSellerType'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { StoryblokBoxType } from '@wh/common/cms/components/bloks/StoryblokBox'
import { ISbStoryData } from '@storyblok/react'
import { getStoryblokStory } from '@wh/common/cms/api/storyblokIadProxyClient'

type ErrorProps =
    | {
          is404: true
      }
    | {
          isNotAvailable: true
      }

type AdDetailProps = {
    advertDetails: AdvertDetails
    profileData: UserProfileData | undefined
    seoSearchTerms: SeoSearchTerms[]
    storyblokPrivateContactBoxBannerBox: StoryblokBoxType | null
}

const BapAdDetail: NextPage<AdDetailProps | ErrorProps> & OptionalPageOptions = (props) => {
    useEffect(() => {
        if ('isNotAvailable' in props || 'is404' in props) {
            return
        }
    }, [props])

    if ('isNotAvailable' in props) {
        return null
    }

    if ('is404' in props) {
        return <AdIdNotFoundAlert />
    }

    const { advertDetails, seoSearchTerms, profileData, storyblokPrivateContactBoxBannerBox } = props

    return (
        <Fragment>
            <AdDetailTagger advertDetails={advertDetails} />

            <SeoMetaTagsForSeoMetaData
                seoMetaData={advertDetails.seoMetaData}
                ogSiteImageUrl={advertDetails.advertImageList.advertImage[0]?.referenceImageUrl}
                dontSetDefaultOgSiteImageDimensions={true}
            />
            <BapAdDetailUserZoom />

            <BapJsonLd advertDetails={advertDetails} />
            <BapAdDetailContainer
                advertDetails={advertDetails}
                sellerType={getSellerType(advertDetails.attributes)}
                searchTerms={seoSearchTerms}
                profileData={profileData}
                key={advertDetails.id}
                storyblokPrivateContactBoxBannerBox={storyblokPrivateContactBoxBannerBox}
            />
        </Fragment>
    )
}

const AdDetailTagger: FunctionComponent<{ advertDetails: AdvertDetails }> = ({ advertDetails }) => {
    const trackEvent = useOptimizelyTrack()

    useEffect(() => {
        callPageView('adview', { taggingData: advertDetails.taggingData })
        trackEvent('bap_addetail_viewed')
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [advertDetails, advertDetails.id])
    return null
}

const initialCmsDataCache = {
    data: null,
    timestamp: 0,
}
const cmsDataCache: {
    data: ISbStoryData<StoryblokBoxType> | null
    timestamp: number
} = initialCmsDataCache

const cacheTimeInMinutes = 5
const getCmsStory = async (params: ExpressNextContextWithUserData) => {
    if (new Date().getTime() - cmsDataCache.timestamp < 1000 * 60 * cacheTimeInMinutes) {
        return cmsDataCache.data
    }

    try {
        const story = await getStoryblokStory<StoryblokBoxType>('403748489', params.req)
        cmsDataCache.data = story?.story ?? null
        return story?.story ?? null
    } catch (e) {
        console.error(e)
        return null
    } finally {
        cmsDataCache.timestamp = new Date().getTime()
    }
}

export const bapAdDetailgetInitialProps = async (params: ExpressNextContextWithUserData): Promise<AdDetailProps | ErrorProps> => {
    const bapAdDetailStory = await getCmsStory(params)

    const adDetailGetInitialPropsFunc = adDetailGetInitialProps(verticalIdMap.BAP)

    const adDetailPromise = adDetailGetInitialPropsFunc(params)
    const searchTermsPromise = getSeoSearchTerms(verticalIdMap.BAP, 30)

    const [adDetail, seoSearchTerms] = await Promise.all([adDetailPromise, searchTermsPromise])

    return {
        ...adDetail,
        seoSearchTerms: [seoSearchTerms],
        storyblokPrivateContactBoxBannerBox: bapAdDetailStory?.content ?? null,
    }
}

BapAdDetail.getInitialProps = bapAdDetailgetInitialProps
BapAdDetail.Layout = LeaderboardAndSkyscraperLayout
BapAdDetail.allowAndroidBanner = false

export default BapAdDetail
