import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { Text } from '@wh-components/core/Text/Text'
import PictureSearch from '@wh-components/icons/PictureSearch'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { getQueryString } from '@wh/common/chapter/lib/urlHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface SimilarAdsButtonProps {
    similarImageSearchUrl?: string | null
    taggingData: TaggingData
}

export const SimilarAdsButton: FunctionComponent<SimilarAdsButtonProps> = ({ similarImageSearchUrl, taggingData }) => {
    if (!similarImageSearchUrl) {
        return null
    }

    const queryString = getQueryString(similarImageSearchUrl)
    const similarImageSearchLink = `/iad/kaufen-und-verkaufen/aehnlichkeitssuche?${queryString}`
    return (
        <ServerRoutingAnchorLink
            type="anchor"
            href={similarImageSearchLink}
            onClick={() => {
                callActionEvent('addetail_image_gallery_similarads_icon_click', taggingData)
            }}
        >
            <Button
                variant="outline"
                width="140px"
                height="32px"
                position="absolute"
                color="complimentary"
                bottom="s"
                right="m"
                zIndex="100"
                paddingHorizontal="xs"
            >
                <Box display="flex" alignItems="center" justifyContent="center">
                    <PictureSearch color="palette.primary.main" size="20px" />
                    <Text fontSize="s" fontWeight="normal" breakWord={true} paddingVertical="xxs" paddingLeft="xs" color="palette.raccoon">
                        Ähnliche Anzeigen
                    </Text>
                </Box>
            </Button>
        </ServerRoutingAnchorLink>
    )
}
