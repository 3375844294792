import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import {
    adContactFormEmailBapFieldRequiredness,
    adContactFormEmailBapFieldToLabelMap,
    BapSendEmailRequestFormData,
    validateAdContactFormEmailBap,
} from './adContactFormEmailBap.types'
import { ContactSuggestion } from '@bbx/common/types/Messaging'
import { FormikHelpers, useFormik } from 'formik'
import { FormikCheckbox, FormikInput, FormikTextarea } from '@wh/common/chapter/components/Formik/FormikInputs'
import { DisabledOnSSRButton } from '@wh/common/chapter/components/DisabledOnSSRButton'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { ItemSpacing } from '@wh/common/chapter/components/Layouts/ItemSpacing'
import { Card } from '@wh-components/core/Card/Card'
import { Desktop5050Container } from '@wh/common/chapter/components/Layouts/rows/Desktop5050Container'
import { FormikSubmitValidationErrorHandler } from '@wh/common/chapter/components/Formik/FormikSubmitValidationErrorHandler'
import EmailIcon from '@wh-components/icons/Email'
import { Box } from '@wh-components/core/Box/Box'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { useErrorFocus } from '@wh/common/chapter/components/Formik/useErrorFocus'

interface BapAdContactFormInnerEmailProps {
    advertDetails: AdvertDetails
    profileData: UserProfileData | undefined
    contactSuggestions: ContactSuggestion[]
    onSendEmail: (data: BapSendEmailRequestFormData, onRetrySendForm: () => void) => void
}

export const BapAdContactFormInnerEmail: FunctionComponent<BapAdContactFormInnerEmailProps> = (props) => {
    const formProps = useFormik({
        initialValues: {
            mailContent: '',
            fromFullName: (props.profileData && `${props.profileData.firstName || ''} ${props.profileData.lastName || ''}`.trim()) || '',
            telephone: props.profileData?.telephone || '',
            from: props.profileData?.emailAddress || '',
            copyToSender: false,
        },
        validateOnBlur: false,
        validateOnChange: true,
        validate: validateAdContactFormEmailBap(props.contactSuggestions.length > 0),
        onSubmit: async (values, formikHelpers: FormikHelpers<BapSendEmailRequestFormData>) => {
            props.onSendEmail(values, formikHelpers.submitForm)
            formikHelpers.setSubmitting(false)
        },
    })

    const formSpecificFieldProps = {
        formProps,
        fieldLabelMap: adContactFormEmailBapFieldToLabelMap,
        fieldRequiredness: adContactFormEmailBapFieldRequiredness,
    }

    const [mailContentFieldFocus, setMailContentFieldFocus] = useState(false)
    const mailContentRef = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
        if (mailContentRef.current !== null && mailContentFieldFocus) {
            mailContentRef.current.focus()
            setMailContentFieldFocus(false)
        }
    }, [formProps, mailContentFieldFocus])

    useErrorFocus(formProps.isSubmitting, formProps.isValidating, formProps.errors)

    return (
        <Card>
            <AdBoxHeading text="Anbieter kontaktieren" marginBottom="s" />
            <FormikSubmitValidationErrorHandler<BapSendEmailRequestFormData> {...formProps} callback={() => {}}>
                {/* use method post in order to prevent field content to land in the browser url in case js breaks on the page */}
                <form method="post" onSubmit={formProps.handleSubmit} noValidate={true} data-testid="ad-contact-form-email">
                    <ItemSpacing>
                        <FormikTextarea
                            field="mailContent"
                            innerRef={mailContentRef}
                            placeholder={
                                props.contactSuggestions.length > 0 ? 'Gib hier deinen Kommentar ein' : 'Gib hier deine Nachricht ein'
                            }
                            {...formSpecificFieldProps}
                            maxLength={5000}
                            rows={4}
                            aria-label="Nachrichteninhalt"
                        />

                        <Desktop5050Container>
                            <FormikInput maxLength={40} field="fromFullName" {...formSpecificFieldProps} />
                        </Desktop5050Container>

                        <Desktop5050Container>
                            <Box marginBottom={{ phone: 'm', desktop: '0' }}>
                                <FormikInput field="from" maxLength={60} {...formSpecificFieldProps} type="email" />
                            </Box>
                            <Box marginBottom={{ phone: 'm', desktop: '0' }}>
                                <FormikInput maxLength={40} field="telephone" {...formSpecificFieldProps} />
                            </Box>
                        </Desktop5050Container>

                        <Desktop5050Container>
                            <FormikCheckbox field="copyToSender" {...formSpecificFieldProps} />
                        </Desktop5050Container>

                        <Desktop5050Container>
                            <DisabledOnSSRButton
                                disabled={formProps.isSubmitting}
                                name="send"
                                type="submit"
                                testId="ad-request-send-mail"
                                size="large"
                                width="100%"
                                Icon={EmailIcon}
                            >
                                E-Mail absenden
                            </DisabledOnSSRButton>
                        </Desktop5050Container>
                    </ItemSpacing>
                </form>
            </FormikSubmitValidationErrorHandler>
        </Card>
    )
}
