import React, { FunctionComponent, useMemo } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import PinIcon from '@wh-components/icons/Pin'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { buildPostCodePart } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { Text } from '@wh-components/core/Text/Text'

export const BapLocationTeaser: FunctionComponent<{ advertDetails: AdvertDetails }> = ({ advertDetails }) => {
    const { address } = useMemo(() => getContactData(advertDetails), [advertDetails])
    const postCodePart = buildPostCodePart(address?.postCode, address?.postalName)

    return (
        <Box display="flex" alignItems="center" fontSize={{ phone: 's', tablet: 'm' }} color="palette.raccoon" width="100%">
            <PinIcon size="xsmall" color="palette.koala" />
            <Text lineClamp={1}>{postCodePart}</Text>
        </Box>
    )
}
