import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { Price } from '@bbx/common/types/Price'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { SpaceProps } from '@wh-components/system/space'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import React, { FunctionComponent } from 'react'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { PayliveryPriceBoxDeliveryOption } from './PayliveryPriceBoxOptions'
import { DeliveryOptionsAdvertDetails } from '@wh/common/paylivery/types/deliveryOptions'
import { PayliveryPriceBoxQuestionMarkTooltip } from './PayliveryPriceBoxQuestionMarkTooltip'

interface PayliveryPriceBoxProps extends SpaceProps {
    mainPrice: Price
    advertStatus: AdvertStatus
    deliveryOptions?: DeliveryOptionsAdvertDetails[]
    testIdPrefix: string
    withDetailedShippingAddons?: boolean
}

export const PayliveryPriceBox: FunctionComponent<PayliveryPriceBoxProps> = ({
    mainPrice,
    advertStatus,
    deliveryOptions,
    testIdPrefix,
    withDetailedShippingAddons = false,
    ...props
}) => {
    if (!mainPrice.value) {
        return null
    }

    return (
        <Box testId={`${testIdPrefix}-paylivery-price-box`} {...props}>
            {mainPrice.value && (
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text
                            fontSize={isFormattedPrice(mainPrice.value) ? { phone: 'xxl', tablet: 'xxxl' } : 'xl'}
                            fontWeight="bold"
                            lineHeight={1}
                            color={advertStatus.id === adStatusFlag.SOLD ? 'palette.elephant' : 'palette.primary.main'}
                            testId={`${testIdPrefix}-price-box-price-value`}
                        >
                            {mainPrice.value}
                        </Text>
                        <PayliveryBadge id={testIdPrefix} />
                    </Box>

                    {deliveryOptions && (
                        <Box display="flex" flexDirection="column" paddingTop="xs">
                            {deliveryOptions.map((option) => (
                                <PayliveryPriceBoxDeliveryOption
                                    deliveryOption={option}
                                    testIdPrefix={testIdPrefix}
                                    withDetailedShippingAddons={withDetailedShippingAddons}
                                    key={`${testIdPrefix}-delivery-option`}
                                />
                            ))}
                            <PayliveryPriceBoxQuestionMarkTooltip testIdPrefix={testIdPrefix} />
                            {advertStatus && advertStatus.id === adStatusFlag.RESERVED && (
                                <Text
                                    fontSize="s"
                                    fontWeight="bold"
                                    color="palette.primary.main"
                                    testId={`${testIdPrefix}-price-box-price-label`}
                                >
                                    RESERVIERT
                                </Text>
                            )}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}
