import { OfferResponse } from '@bbx/common/types/Messaging'
import { getP2pBuyNowIntegratioName, getP2pMakeOfferIntegratioName } from '@wh/common/chapter/lib/config/runtimeConfig'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { initConversation } from '../api/adContactApiClient'

type P2PFlowType = 'MAKE_OFFER' | 'BUY_NOW'

export const redirectToChat = async (adId: string, flowType: P2PFlowType) => {
    const offerResponse: OfferResponse = await initConversationWithOneRetry(adId)
    const p2pName: string = flowType === 'BUY_NOW' ? getP2pBuyNowIntegratioName() : getP2pMakeOfferIntegratioName()
    let redirectLocation
    if (!offerResponse.conversationId) {
        redirectLocation = `/iad/myprofile/chat`
    } else {
        redirectLocation = `/iad/myprofile/chat/id/${offerResponse.conversationId}?integrationToOpen=${p2pName}`
    }
    BbxRouter.push({ href: redirectLocation, clientSideRouting: true })
}

const initConversationWithOneRetry = async (adId: string): Promise<OfferResponse> => {
    let offerResponse: OfferResponse = await initConversation(adId)
    if (!offerResponse.conversationId) {
        offerResponse = await initConversation(adId)
    }
    return offerResponse
}
