import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { useGeoData } from '@bbx/search-journey/common/lib/useGeoData'
import { useProfileTrustSignals } from '@bbx/search-journey/common/lib/useProfileTrustSignals'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { KeyValue } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/KeyValuePair'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { PrivateProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PrivateProfile'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { AdDetailMap } from '@bbx/search-journey/sub-domains/ad-detail/components/common/Map/AdDetailMap'
import { SellerTypeInfoBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SellerTypeInfoBox/SellerTypeInfoBox'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import React, { FunctionComponent, useMemo } from 'react'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'

interface BapContactBoxPrivateBottomProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetails
}

export const BapContactBoxPrivateBottom: FunctionComponent<BapContactBoxPrivateBottomProps> = ({ advertDetails, ...props }) => {
    const { name, contactName, image, userSince, address, phoneNumbers, companyName, activeAdCount, contactFirstName } = useMemo(
        () => getContactData(advertDetails),
        [advertDetails],
    )

    const geoData = useGeoData(advertDetails)

    const trustProfileLink = findContextLinkWithId('trustProfileLink', advertDetails.contextLinkList)
    const { replyTime, presenceText, payliverySales, reputation } = useProfileTrustSignals(trustProfileLink)

    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)
    const sellerProfileWebLink =
        organisationProfileWeblink ?? `/iad/kaufen-und-verkaufen/verkaeuferprofil/${advertDetails.organisationDetails.id}`
    const optimizelyTrack = useOptimizelyTrack()

    return (
        <Card padding={0} backgroundColor="palette.white" testId="ad-detail-contact-box-private-bottom" {...props}>
            <PrivateProfile
                companyName={companyName}
                name={contactName || name}
                image={image}
                userSince={userSince}
                replyTime={replyTime}
                presenceText={presenceText}
                payliverySales={payliverySales}
                testIdPrefix="bottom"
                feedback={reputation?.feedback}
                shouldShowPayLiveryRatings={true}
            />
            <Box display="flex" flexDirection="column" gap="m" padding="m" paddingTop={0}>
                <SellerInfoButton
                    link={sellerProfileWebLink}
                    activeAdCount={activeAdCount}
                    clickTaggingActionEvent="addetail_more_ads_click"
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="bottom"
                    onClick={() => optimizelyTrack('bbx_bottom_contact_box_click_more_ads_from_seller')}
                >
                    Weitere Anzeigen von {contactFirstName ?? 'diesem Verkäufer'}
                </SellerInfoButton>
                <KeyValue
                    title="Adresse"
                    testIdPrefix="contact-box-private-bottom"
                    content={address && <AddressBox address={address} testIdPrefix="bottom" />}
                />
                <PhoneNumberButton
                    variant="link"
                    phoneNumbers={phoneNumbers}
                    toggleWithContext={true}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="bottom"
                />
            </Box>
            {geoData.type !== 'none' && (
                <DynamicRendering<HTMLDivElement>
                    key="ad-detail-map"
                    offset="300px"
                    renderPlaceholder={(ref) => <Box ref={ref} height={150} testId="ad-detail-map-dynamic-placeholder" />}
                >
                    <Box paddingHorizontal={{ phone: 'm', tablet: 0 }} marginBottom="m">
                        <AdDetailMap
                            height={150}
                            center={geoData.coordinates}
                            adId={advertDetails.id}
                            taggingData={advertDetails.taggingData}
                            circle={geoData.type === 'radius' ? geoData.radius : undefined}
                            geoJSON={geoData.type === 'geoJson' ? geoData.geoJson : undefined}
                        />
                    </Box>
                </DynamicRendering>
            )}
            <Box marginLeft="m" marginBottom={{ tablet: 'm' }}>
                <SellerTypeInfoBox attributes={advertDetails.attributes} />
            </Box>
        </Card>
    )
}
