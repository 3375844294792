import { Text } from '@wh-components/core/Text/Text'
import React, { Fragment, FunctionComponent } from 'react'
import { formatPrice } from '@wh/common/chapter/lib/formatter'
import { css } from 'styled-components'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { Box } from '@wh-components/core/Box/Box'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'
import { getShippingAddOnDescriptionByAddOnType, getShippingAddonTypeFromAddOns } from '@wh/common/paylivery/payliveryUtils'
import { DeliveryOptionsAdvertDetails } from '@wh/common/paylivery/types/deliveryOptions'

export const PayliveryPriceBoxDeliveryOption: FunctionComponent<{
    deliveryOption: DeliveryOptionsAdvertDetails
    testIdPrefix: string
    withDetailedShippingAddons: boolean
}> = ({ deliveryOption, testIdPrefix, withDetailedShippingAddons }) => {
    // if the price property matches the original price of the ad the ad is not part of the shipping campaign
    // therefore it will display previous UI.
    const isShippingCampaignBapAd = deliveryOption.price !== deliveryOption.originalPrice

    const shippingPriceFormatted = formatPrice(isShippingCampaignBapAd ? deliveryOption.price : deliveryOption.originalPrice)
    const originalPriceFormatted = formatPrice(deliveryOption.originalPrice)

    const shippingAddOns = deliveryOption.shippingAddOns
    const hasShippingAddOns = shippingAddOns.length > 0
    const shippingAddOnPostfixText = shippingAddOns.map((addon) => addon.optionName).join(', ')
    const shippingAddOnDescription = getShippingAddOnDescriptionByAddOnType(getShippingAddonTypeFromAddOns(shippingAddOns))

    return (
        <Box display="flex" alignItems={{ phone: hasShippingAddOns && withDetailedShippingAddons ? 'start' : 'center', tablet: 'center' }}>
            <Box>
                <Text key={deliveryOption.uuid} fontSize="s" color="palette.raccoon" testId={`${testIdPrefix}-price-box-carrier-label`}>
                    +{' '}
                    {isShippingCampaignBapAd ? (
                        <React.Fragment>
                            <Text
                                as="span"
                                testId={`${testIdPrefix}-price-box-price-strike-through`}
                                css={css`
                                    text-decoration: line-through;
                                `}
                            >
                                {originalPriceFormatted}
                            </Text>
                            <Text as="span" color="palette.primary.main">
                                {' '}
                                {shippingPriceFormatted === '€ 0' ? '€ 0,00' : shippingPriceFormatted} Versandkosten
                            </Text>
                        </React.Fragment>
                    ) : (
                        <Text as="span">{shippingPriceFormatted} Versandkosten </Text>
                    )}
                    {withDetailedShippingAddons && hasShippingAddOns && <Text as="span">{` (${shippingAddOnPostfixText})`}</Text>}
                </Text>
            </Box>
            <Box display="flex" alignItems="center" marginTop={{ phone: 'xs', tablet: 0 }}>
                <Box display="flex">
                    <ResponsiveImage
                        src={deliveryOption.carrier.iconUrl}
                        alt={deliveryOption.carrier.description}
                        title={deliveryOption.carrier.description}
                        width={16}
                        marginLeft="xs"
                    />
                </Box>
                {hasShippingAddOns && (
                    <Tooltip
                        interactive={true}
                        content={
                            <Fragment>
                                {shippingAddOnDescription.map((description, index) => (
                                    <Fragment key={index}>
                                        {index > 0 && <br />}
                                        <Text as="p" key={index}>
                                            {description}
                                        </Text>
                                    </Fragment>
                                ))}
                            </Fragment>
                        }
                    >
                        <Box display="flex" marginLeft="xs" testId="shippingAddOnsTooltip">
                            <TooltipQuestionmark color="palette.raccoon" size="xsmall" aria-hidden={true} />
                        </Box>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}
