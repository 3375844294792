import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { isCat, isDog } from '@bbx/search-journey/sub-domains/ad-detail/lib/animalHelper'

export const AnimalSecurityHints: FunctionComponent<{ categoryId: number | null }> = ({ categoryId }) => {
    const isDogAnimal = isDog(categoryId)
    const isCatAnimal = isCat(categoryId)
    return isCatAnimal || isDogAnimal ? (
        <Box marginTop="m" marginHorizontal={{ phone: 'm', tablet: 0 }} testId="animal-security-hints">
            <Divider />
            <Text marginVertical="m" as="p">
                So schützt du dich vor illegalen Tierhändlern.{' '}
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={
                        isDogAnimal
                            ? 'https://www.willhaben.at/iad/sicherheitshinweise/welpenkauf'
                            : 'https://www.willhaben.at/iad/sicherheitshinweise/kaufen'
                    }
                >
                    Erfahre mehr...
                </ClientRoutingAnchorLink>
            </Text>
            <Divider />
        </Box>
    ) : null
}
