import React, { FunctionComponent } from 'react'
import { AdServiceGroup } from '@bbx/common/types/ad-detail/AdServices'
import { ServiceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ServiceBox/ServiceBox'
import { SpaceProps } from '@wh-components/system/space'

export const BapServiceBox: FunctionComponent<{ serviceBoxGroups: AdServiceGroup[] | null; showInColumns?: boolean } & SpaceProps> = ({
    serviceBoxGroups,
    showInColumns,
    ...props
}) => {
    if (!serviceBoxGroups) {
        return null
    }
    return (
        <ServiceBox
            serviceBoxGroups={serviceBoxGroups}
            display={{ phone: 'block', print: 'none' }}
            showInColumns={showInColumns}
            {...props}
        />
    )
}
