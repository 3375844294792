import { UserProfileData } from '../chapter/types/user'
import { ShippingAddons } from './types/deliveryOptions'

type ShippingAddonType = 'fragile' | 'oversize' | 'fragileAndOversize' | ''

const fragileDescription =
    'Bei der Zusatzoption "Erweiterte Haftung" haftet die Post bis € 510, sollte dein erschütterungsanfälliger oder zerbrechlicher Artikel während des Transports beschädigt werden. Eine entsprechend gute, stoßfeste Verpackung des Artikels ist dennoch Voraussetzung.'
const oversizeDescription =
    '"Sperrgut" umfasst alle Paketformen die nicht rechteckig sind oder wenn der Artikel größer als 100 x 60 x 60cm ist.'

const fragileUuid = 'e87f3937-5a2d-4e63-b1f0-0102f2862007'
const oversizeUuid = '1b27afa3-8b38-49f5-b635-85c7e905f704'

export const getShippingAddonTypeFromAddOns = (shippingAddons: ShippingAddons[]): ShippingAddonType => {
    const uuids = shippingAddons.map((addon) => addon.uuid)
    const isFragile = uuids.includes(fragileUuid)
    const isOverSize = uuids.includes(oversizeUuid)

    if (isFragile && isOverSize) {
        return 'fragileAndOversize'
    } else if (isFragile) {
        return 'fragile'
    } else if (isOverSize) {
        return 'oversize'
    } else {
        return ''
    }
}

export const getShippingAddOnDescriptionByAddOnType = (shippingAddonType: ShippingAddonType) => {
    if (shippingAddonType === 'fragile') {
        return [fragileDescription]
    } else if (shippingAddonType === 'oversize') {
        return [oversizeDescription]
    } else if (shippingAddonType === 'fragileAndOversize') {
        return [fragileDescription, oversizeDescription]
    } else {
        return []
    }
}

export const isUserBlockedByDac7 = (reminder: UserProfileData['dac7NotificationAttempt']) => {
    return reminder === 'PAYLIVERY_BLOCKED'
}
