import React, { FunctionComponent } from 'react'
import { AdvertDetails, AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import dayjs from 'dayjs'
import { getSeller } from '@bbx/search-journey/common/components/JsonLd/Helpers/personHelper'
import { Product, WithContext } from 'schema-dts'
import { JsonLdScriptElement } from '@wh/common/chapter/components/JsonLd/JsonLdScriptElement'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { getFirstAttribute, getFirstAttributeLabel } from '@bbx/common/lib/get-attributes'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'

interface BapJsonLdProps {
    advertDetails: AdvertDetails | undefined
}

export const BapJsonLd: FunctionComponent<BapJsonLdProps> = ({ advertDetails }) => {
    try {
        if (advertDetails) {
            const advertPrice = getAttributeValue(advertDetails.attributes.attribute, 'PRICE')
            const priceValidUntil = dayjs(advertDetails.endDate).format('YYYY-MM-DD')
            const seller = getSeller(advertDetails)
            const brand = getFirstAttributeLabel(advertDetails.attributeInformation, 'BRAND')
            const condition = getFirstAttribute(advertDetails.attributeInformation, 'Zustand')?.code
            const itemCondition = getBapItemCondition(condition)
            const productJsonLd: WithContext<Product> = {
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: advertDetails.description,
                brand: brand,
                description: advertDetails.seoMetaData.description,
                sku: advertDetails.id,
                image: advertDetails.advertImageList.advertImage[0]
                    ? advertDetails.advertImageList.advertImage[0].referenceImageUrl
                    : undefined,
                url: advertDetails.seoMetaData.canonicalUrl,
                offers: {
                    '@type': 'Offer',
                    price: advertPrice,
                    priceCurrency: 'EUR',
                    priceValidUntil: priceValidUntil,
                    availability: getAvailability(advertDetails.advertStatus),
                    itemCondition: itemCondition,
                    url: advertDetails.seoMetaData.canonicalUrl,
                    seller: seller,
                },
            }
            return <JsonLdScriptElement key="jsonld-bap" jsonLd={productJsonLd} />
        }
        return null
    } catch (error) {
        captureException(error)
        return null
    }
}

const getBapItemCondition = (conditionCode: string | undefined) => {
    switch (conditionCode) {
        case 'neu':
        case 'MintCondition':
            return 'NewCondition'
        case 'gebraucht':
            return 'UsedCondition'
        case 'defekt':
            return 'DamagedCondition'
        default:
            return 'UsedCondition'
    }
}

const getAvailability = (advertStatus: AdvertStatus) => {
    if (advertStatus.id === adStatusFlag.RESERVED) {
        return 'LimitedAvailability'
    } else if (advertStatus.id === adStatusFlag.SOLD || advertStatus.id === adStatusFlag.GIVEN_AWAY) {
        return 'SoldOut'
    }

    return 'InStock'
}
