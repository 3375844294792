import React, { FunctionComponent, RefObject } from 'react'
import { SecurityHintsPrivate } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SecurityHints/SecurityHintsPrivate'
import { isCat, isDog } from '@bbx/search-journey/sub-domains/ad-detail/lib/animalHelper'

const securityHintsGeneral = [
    'Nutze, wenn möglich, unser sicheres Bezahl- und Versandservice PayLivery. Neben einer komfortablen Abwicklung bietet dir PayLivery auch Sendungsverfolgung und Käuferschutz.',
    'Ansonsten empfehlen wir, sich von dem angebotenen Artikel vor Ort zu überzeugen, diesen persönlich entgegen zu nehmen und im Zuge dessen zu bezahlen.',
    'Sollte dies nicht möglich sein, so raten wir dir zu einer möglichst sicheren Zahlungs-/Versandoption, wie etwa Postversand per Nachnahme.',
    'Von Bargeldtransfers (z.B. Western Union oder MoneyGram), Scheck, Gutscheinen oder Geldversand via Post Abstand nehmen. Diese Services bieten dir keinen Schutz gegen Betrug im Internet.',
    'Handelt es sich um einen hochpreisigen Artikel, raten wir davon ab, diesen per Vorkasse zu bezahlen, da du den Empfänger des Geldes in den meisten Fällen nicht kennst und dieser dich unter Umständen mit gefälschten Papieren um deine (An-)Zahlung betrügen könnte.',
]
const securityHintsDogs = [
    'Die Welpen sollten bei der Abgabe mindestens acht Wochen oder älter sein.',
    'Die Übergabe der Welpen sollte nur beim Anbieter zu Hause stattfinden. Akzeptiere keine Übergaben auf öffentlichen Plätzen oder "Hauszustellung".',
    'Schau dir die Elterntiere an, insbesondere das Muttertier. Es ist wichtig, dass du die genaue Herkunft der Welpen kennst und ihre Geburtsstätte besichtigen darfst.',
    'Von Verkäufern aus dem Ausland und Überweisungen ins Ausland Abstand nehmen. Kauf kein Tier aus Mitleid sondern verständige die Behörden.',
    'Achtung bei Verkäufern, die verschiedene Rassen anbieten: Es kann sich um unseriöse Welpenhändler handeln.',
    'Gib Acht bei zu günstigen Welpen-Anzeigen! Bei nicht fachgerecht gezüchteten Welpen können hohe Tierarztkosten auf dich zukommen, die Tiere krank werden und sogar kurz nach dem Kauf an den Folgen sterben.',
]

const securityHintsCats = [
    'Die Kätzchen sollten bei der Abgabe mindestens zehn Wochen oder älter sein.',
    'Die Übergabe der Kätzchen sollte nur beim Anbieter zu Hause stattfinden. Akzeptiere keine Übergaben auf öffentlichen Plätzen oder "Hauszustellung".',
    'Schau dir die Elterntiere an, insbesondere das Muttertier. Es ist wichtig, dass du die genaue Herkunft der Kätzchen kennst und ihre Geburtsstätte besichtigen darfst.',
    'Von Verkäufern aus dem Ausland und Überweisungen ins Ausland Abstand nehmen. Kaufe kein Tier aus Mitleid sondern verständige die Behörden.',
    'Achtung bei Verkäufern, die verschiedene Rassen anbieten: Es kann sich um unseriöse Händler handeln',
]

export const BapSecurityHints: FunctionComponent<{ categoryId: number | null; securityHintsRef?: RefObject<HTMLAnchorElement> }> = ({
    categoryId,
    securityHintsRef,
}) => {
    let securityHintsToDisplay = securityHintsGeneral
    let href = '/iad/sicherheitshinweise/kaufen'
    if (isDog(categoryId)) {
        securityHintsToDisplay = securityHintsDogs
        href = '/iad/sicherheitshinweise/welpenkauf'
    } else if (isCat(categoryId)) {
        securityHintsToDisplay = securityHintsCats
    }
    return (
        <SecurityHintsPrivate
            heading="Die wichtigsten Tipps zum sicheren Kaufen:"
            hints={securityHintsToDisplay}
            href={href}
            securityHintsRef={securityHintsRef}
        />
    )
}
