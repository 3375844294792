import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { SimilarAdsContextLink } from '@bbx/common/types/contextLinks'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { Heading } from '@wh-components/core/Heading/Heading'
import { getSimilarAds } from '@bbx/search-journey/common/api/similarAdsApiClient'
import { AdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummary'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { verticals } from '@wh/common/chapter/types/verticals'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import {
    trackSimilarAdsEndlessBapClick,
    trackSimilarAdsImpressionEndlessBap,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/SimilarAds/useSimilarAds'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'

export interface BapSimilarAdsEndlessProps {
    similarAdsRecommendationContextLink: SimilarAdsContextLink
    taggingData: TaggingData
}

export const BapSimilarAdsEndless: FunctionComponent<BapSimilarAdsEndlessProps> = ({
    similarAdsRecommendationContextLink,
    taggingData,
}) => {
    const numberOfAdsInSlider = 4

    const [contextLink, setContextLink] = useState(similarAdsRecommendationContextLink)
    const { result, more, nextLink } = useEndlessScrollLoad(contextLink)

    const titleVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const titleVisibilityTrackingCallback = useCallback(() => {
        trackSimilarAdsImpressionEndlessBap(taggingData, result)
    }, [taggingData, result])
    useIntersectionObserver(titleVisibilityTrackingRef, { triggerOnce: true }, [], titleVisibilityTrackingCallback)

    const intersectionObserver = useRef<HTMLDivElement>(null)
    const intersectionCallback = useCallback(() => {
        if (more && nextLink) {
            setContextLink(nextLink)
        }
    }, [more, nextLink])
    useIntersectionObserver(intersectionObserver, { triggerOnce: true, threshold: 0.1 }, [], intersectionCallback)

    return (
        <Box id="bap-similar-ads-endless">
            <Heading text="Noch mehr ähnliche Anzeigen" ref={titleVisibilityTrackingRef} />
            <Box
                display="flex"
                flex="1 1 auto"
                alignContent="flex-start"
                flexWrap="wrap"
                paddingVertical="s"
                css={css`
                    & > * {
                        width: 50%;

                        ${(p) => p.theme.media.tablet} {
                            width: 20%;
                        }
                    }
                `}
            >
                {result?.slice(numberOfAdsInSlider).map((advertSummary: AdvertSummary, index: number) => (
                    <Box
                        display="block"
                        padding="s"
                        key={index}
                        css={css`
                            text-wrap: wrap;
                            ${adFocusHoverStyles(false, 0)}
                        `}
                        ref={index === result?.length - 1 - numberOfAdsInSlider ? intersectionObserver : undefined}
                    >
                        <AdvertSummaryComponent
                            attributes={advertSummary.attributes.attribute}
                            teaserAttributes={advertSummary.teaserAttributes}
                            onClickTaggingEvent={() => {
                                trackSimilarAdsEndlessBapClick(taggingData, advertSummary.id, index)
                            }}
                            key={advertSummary.id}
                            vertical={verticals.BAP}
                            adTypeId={advertSummary.adTypeId}
                            description={advertSummary.description}
                            href={getSeoUrl(advertSummary)}
                            advertImage={advertSummary.advertImageList?.advertImage?.[0]?.mainImageUrl}
                            advertStatus={advertSummary.advertStatus}
                            adId={advertSummary.id}
                            variant="portrait"
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export const useEndlessScrollLoad = (contextLink: SimilarAdsContextLink) => {
    const [result, setResult] = useState<AdvertSummary[]>([])
    const [more, setMore] = useState<boolean>(false)
    const [nextLink, setNextLink] = useState<SimilarAdsContextLink>()

    useEffect(() => {
        async function fetchSimilarAds() {
            const searchResult = await getSimilarAds(contextLink)

            if (searchResult?.advertSummaryList?.advertSummary?.length > 0) {
                setResult((prev) => {
                    if (prev !== undefined && prev.length > 0 && prev[0].id !== searchResult.advertSummaryList.advertSummary[0].id) {
                        return [...prev, ...searchResult.advertSummaryList.advertSummary]
                    } else {
                        return searchResult.advertSummaryList.advertSummary
                    }
                })
            }
            const nextPage = searchResult?.pagingLinksList?.contextLink.find((contextLink1) => contextLink1.description === 'nextLink')
            setMore(nextPage !== undefined)
            if (nextPage) {
                setNextLink(nextPage as SimilarAdsContextLink)
            }
        }

        fetchSimilarAds()
    }, [contextLink])

    return { result, more, nextLink }
}
