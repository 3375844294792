import { AdvertDetails, isContactPossible } from '@bbx/common/types/ad-detail/AdvertDetails'
import { BapAdContactFormInnerEmail } from '@bbx/lead-journey/lead-journey/components/AdContactForm/bap/BapAdContactFormInnerEmail'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { callActionEvent, callPageViewWithPageParameters } from '@wh/common/chapter/lib/tagging/tagging'
import React, { FunctionComponent, useContext, useMemo } from 'react'
import {
    needsToAcceptSmsAllowance as defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted as defaultSetSmsAllowanceStatusAccepted,
} from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { RequestSendSuccessEmail } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormRequestSendSuccess'
import { AdContactFormRequestStateContainer } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormRequestStateContainer'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { BapSendEmailRequestFormData } from './adContactFormEmailBap.types'
import { Card } from '@wh-components/core/Card/Card'
import {
    BapAdContactFormProps,
    BapAdDetailContactFormMessaging,
} from '@bbx/lead-journey/lead-journey/components/AdContactForm/bap/BapAdDetailContactFormMessaging'
import { OrderNowContactForm } from '@bbx/lead-journey/lead-journey/components/AdContactForm/bap/OrderNowContactForm'
import {
    sendRequestMail as defaultSendRequestMail,
    sendRequestMessage as defaultSendRequestMessage,
} from '@bbx/lead-journey/lead-journey/api/adContactApiClient'

export const BapAdContactForm: FunctionComponent<
    BapAdContactFormProps & {
        priceBox: (advertDetails: AdvertDetails) => React.ReactNode
    }
> = ({
    mainPrice,
    priceBox,
    sendRequestMail = defaultSendRequestMail,
    sendRequestMessage = defaultSendRequestMessage,
    needsToAcceptSmsAllowance = defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted = defaultSetSmsAllowanceStatusAccepted,
    ...props
}) => {
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(props.advertDetails), [props.advertDetails])
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    if (!isContactPossible(props.advertDetails.advertStatus)) {
        return null
    }

    switch (contactTypeWithDetails.type) {
        case 'EMAIL': {
            return (
                <AdContactFormRequestStateContainer<{
                    data: BapSendEmailRequestFormData
                }>
                    adId={props.advertDetails.id}
                    adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
                    requestSendSuccess={() => (
                        <RequestSendSuccessEmail adId={props.advertDetails.id} adTypeId={props.advertDetails.adTypeId} isHouse={false} />
                    )}
                    requestForm={(handleSend) => (
                        <BapAdContactFormInnerEmail
                            advertDetails={props.advertDetails}
                            profileData={props.profileData}
                            contactSuggestions={contactTypeWithDetails.contactSuggestions}
                            onSendEmail={handleSend}
                        />
                    )}
                    preSendCheck={async (formData) => {
                        trackCustomDmpEvent('UserAction', { eventName: 'TapSendMessage' })
                        callActionEvent('addetail_send_email_click', props.advertDetails.taggingData)
                        // INFO user does not need to be logged in to send an email request
                        return { result: 'success', pendingRequestData: { data: formData }, emailAddressForSmsAllowance: formData.from }
                    }}
                    sendNetworkRequest={(pendingRequestData, _emailAddress) => {
                        const { mailContent, ...data } = pendingRequestData.data
                        return sendRequestMail({
                            adId: +props.advertDetails.id,
                            showTelephoneNumber: (pendingRequestData.data.telephone?.length ?? 0) > 0,
                            mailContent,
                            ...data,
                        })
                    }}
                    onSendSuccess={(pendingRequestData) => {
                        props.setAdvertisingStateContacted()
                        trackCustomDmpEvent('UserAction', { eventName: 'MessageSent' })
                        callPageViewWithPageParameters(
                            {
                                page: 'contact_seller_confirmation_dealer_bap',
                                selectedContactSuggestions: [],
                                hasFilledOutMessageField: pendingRequestData.data.mailContent.trim().length > 0,
                            },
                            props.advertDetails.taggingData,
                        )
                    }}
                    needsToAcceptSmsAllowance={needsToAcceptSmsAllowance}
                    setSmsAllowanceStatusAccepted={setSmsAllowanceStatusAccepted}
                />
            )
        }
        case 'MESSAGING':
            return (
                <Card>
                    <BapAdDetailContactFormMessaging
                        mainPrice={mainPrice}
                        sendRequestMail={sendRequestMail}
                        sendRequestMessage={sendRequestMessage}
                        needsToAcceptSmsAllowance={needsToAcceptSmsAllowance}
                        setSmsAllowanceStatusAccepted={setSmsAllowanceStatusAccepted}
                        {...props}
                    />
                </Card>
            )
        default:
            return (
                <OrderNowContactForm
                    advertDetails={props.advertDetails}
                    contactTypeWithDetails={contactTypeWithDetails}
                    priceBox={priceBox}
                />
            )
    }
}
