import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { Price } from '@bbx/common/types/Price'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { PriceStatusLabel } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/bap/ContactBox/PriceStatusLabel'

interface NoliveryPriceBoxProps extends SpaceProps {
    mainPrice: Price
    advertStatus: AdvertStatus
}

export const NoliveryPriceBox: FunctionComponent<NoliveryPriceBoxProps> = ({ mainPrice, advertStatus, ...props }) => {
    if (!mainPrice.value) {
        return null
    }

    return (
        <Box testId="contact-box-price-box" {...props}>
            {mainPrice.value && (
                <Box display="flex" flexDirection="column">
                    <Text
                        fontSize={isFormattedPrice(mainPrice.value) ? { phone: 'xxl', tablet: 'xxxl' } : 'xl'}
                        paddingBottom="xs"
                        fontWeight="bold"
                        lineHeight={1}
                        color={advertStatus.id === adStatusFlag.SOLD ? 'palette.elephant' : 'palette.primary.main'}
                        testId="contact-box-price-box-price-value"
                    >
                        {mainPrice.value}
                    </Text>
                    <PriceStatusLabel mainPrice={mainPrice} advertStatus={advertStatus} />
                </Box>
            )}
        </Box>
    )
}
